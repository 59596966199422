import React, { useEffect } from "react"
import Sidenav from "../../../components/UI/Sidenav4.component"

import Sidenav_mb from "../../../components/UI/Sidenav_mb3.component"
import JSONData from "../../../content/content.json"
import Portfolio2 from "../../../components/Portfolio.component"
import SEO from "../../../components/seo"
import { Waypoint } from "react-waypoint"

import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll"
const Portfolio = () => {
  const dataSet = [
    JSONData.portfolio.mapleton,
    JSONData.portfolio.belair,
    JSONData.portfolio.tower,
  ]

  const checkthis = () => {
    document.getElementById("foo6").className = "Navs-pre3 active"
    document.getElementById("foo7").className = "Navs-pre3"
  }
  const checkthis3 = () => {
    document.getElementById("foo7").style.color = "white"
  }
  const checkthis2 = () => {
    document.getElementById("foo6").style.color = "#ffe200"
    document.getElementById("foo7").style.color = "white"
    document.getElementsByClassName("Navs-pre3")[0].style.color =
      "white !important"
  }
  const leavethis = () => {
    document.getElementById("foo7").className = "Navs-pre3 active"
    document.getElementById("foo6").className = "Navs-pre3"
  }
  const leavethis2 = () => {
    document.getElementById("foo6").style.color = "white"
    document.getElementById("foo7").style.color = "#ffe200"
  }
  return (
    <>
      <SEO title="Portfolio" />
      <div>
        <section id="Mapleton">
          <Element name="Mapleton" className="element">
            <Sidenav />
            <Sidenav_mb />

            <div className="Gallery-Container">
              <div className="Portfolio-font">PORTFOLIO</div>
            </div>
            <Portfolio2
              data={dataSet[0]}
              hasVideo={true}
              hasBrochure={true}
              id="Mapleton"
            />
          </Element>
        </section>
        <div className="portfolio-space3"></div>
        <Element name="BelAir" className="element">
          <Portfolio2
            hasVideo={true}
            hasBrochure={true}
            data={dataSet[1]}
            id="BelAir"
          />
        </Element>

        <div className="portfolio-space3"></div>
        <Portfolio2
          hasVideo={false}
          hasBrochure={false}
          data={dataSet[2]}
          id="RTower"
        />
        <div className="portfolio-space"></div>
      </div>
    </>
  )
}

export default Portfolio
