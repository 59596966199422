import React, { useState, useRef } from "react"
import NextButton3 from "../images/rightarrow2.png"
import Slider from "react-slick"
import JSONData from "../content/content.json"
import Lightbox from "react-image-lightbox"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Portfolio = ({ data, id, hasVideo, hasBrochure }) => {
  const [isOpen, setisOpen] = useState(false)

  const [mousedownPos, setmousedownPos] = useState({
    mousedownPosX: 0,
    mousedownPosY: 0,
  })
  const [photoIndex, setphotoIndex] = useState(0)
  const [index, setIndex] = useState(0)
  const settings = {
    infinite: true,
    dots: true,
    adaptiveHeight: true,
    slidesToShow: 3,
    centerMode: true,
    arrows: true,
    centerPadding: "5%",
    nextArrow: <SampleNextArrow />,

    responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  const OpenLightbox = (e, i) => {
    if (
      mousedownPos.mousedownPosX !== e.clientX ||
      mousedownPos.mousedownPosY !== e.clientY
    ) {
    } else {
      setphotoIndex(i)
      setisOpen(true)
    }
  }

  const handleOnMouseDown = e => {
    setmousedownPos({
      mousedownPosX: e.clientX,
      mousedownPosY: e.clientY,
    })
  }
  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          Opacity: 0,
        }}
        onClick={onClick}
      >
        <img src={NextButton3} className="rendering-next2" />
      </div>
    )
  }
  const next = () => {
    slideRef.current.slickNext()
    slideRef.current.slickNext()

    slideRef.current.slickNext()
  }
  const [fullscreen, setFullscreen] = useState(true)

  const videoRef = useRef()
  const slideRef = useRef()
  const [numPages, setNumPages] = useState(null)

  const [pageNumber, setPageNumber] = useState(1)
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  const [show, setShow] = useState(false)

  const handleshow = () => {
    setShow(true)
  }
  return (
    <>
      <section id={id}>
        <div>
          {isOpen && (
            <Lightbox
              mainSrc={data.images_orig[photoIndex]}
              nextSrc={
                data.images_orig[(photoIndex + 1) % data.images_orig.length]
              }
              prevSrc={
                data.images_orig[
                  (photoIndex + data.images.length - 1) % data.images.length
                ]
              }
              onCloseRequest={() => setisOpen(false)}
              onMovePrevRequest={() =>
                setphotoIndex(
                  (photoIndex + data.images.length - 1) % data.images.length
                )
              }
              onMoveNextRequest={() =>
                setphotoIndex((photoIndex + 1) % data.images.length)
              }
            />
          )}
          <div className="portfolio-container">
            <div className="portfolio-space2"></div>

            <div className="portfolio-title">{data.title}</div>
            {hasVideo && (
              <div>
                <div className="portfolio-subtitle">/ VIDEO</div>
                <iframe
                  src={data.vimeo}
                  className="portfolio-vimeo"
                  allowfullscreen="allowfullscreen"
                ></iframe>

                {data.vimeo_detail.map(detail3 => {
                  return (
                    <div className="portfolio-vimeo-link">
                      <a target="_blank" href={detail3.url}>
                        {" "}
                        {detail3.title}
                      </a>
                    </div>
                  )
                })}
                <div className="portfolio-space4"></div>
              </div>
            )}

            {!hasVideo && (
              <div>
                <div className="portfolio-subtitle">/ THUMBNAIL</div>
                <img src={data.thumbnail} className="portfolio-thumbnail" />
              </div>
            )}
            <div className="portfolio-subtitle">/ GALLERY</div>
            <Slider {...settings} ref={slider => (slideRef.current = slider)}>
              {data.images.map((image, i) => {
                return (
                  <div
                    className="Details-Slide"
                    onClick={e => OpenLightbox(e, i)}
                    onMouseDown={e => handleOnMouseDown(e)}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src={image}
                      placeholderSrc={<h1>loading...</h1>}
                      placeholder={<h1>test</h1>}
                      visibleByDefault={true}
                      className="render01"
                    />{" "}
                  </div>
                )
              })}
            </Slider>
            {hasBrochure && (
              <div>
                <div className="portfolio-space"></div>

                <div className="portfolio-subtitle">/ BROCHURE</div>

                <a href={data.brochure} target="_blank">
                  <img src={data.brothumbnail} className="portfolio-brochure" />
                </a>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default Portfolio
