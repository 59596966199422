import React from "react"
import Burger from "../../images/burger.png"
import Cross from "../../images/Cross.svg"
import AltairLogoWhiteSVG from "../../images/altair-logo-white.svg"
import { motion } from "framer-motion"

import { Link, animateScroll as scroll } from "react-scroll"

class Sidenav extends React.Component {
  showSettings(event) {
    event.preventDefault()
  }

  constructor(props) {
    super(props)
    this.state = {
      positionIdx: 0,
      dimModeIdx: 0,
      isVisible: false,
      fluid: true,
      customAnimation: false,
      slow: false,
      size: 1,
      mode: "Wrapper-mb",
      img: Burger,
      pressed: false,
      MenuVisible: false,
    }
  }

  Changepath = () => {
    if (!this.state.pressed) {
      this.setState({ mode: "Wrapper-mb" })
      this.setState({ img: Cross })
      this.setState({ pressed: true })
      setTimeout(() => {
        this.setState({ MenuVisible: true })
      }, 200)
      document.getElementById("AltairLogo").style.transition = "opacity 0.4s"
      document.getElementById("AltairLogo").style.opacity = "0"
      document.getElementById("foo2").style.transition =
        "clip-path 0.4s ,height 0.4s, EaseInOut"
      document.getElementById("foo2").style.clipPath =
        "polygon(0 0, 100% 0, 100% 100%, 0% 100%)"
      document.getElementById("foo2").style.height = "160px"
      document.getElementById("foo3").style.transition =
        "width,height 0.4s EaseInOut"
      document.getElementById("foo3").style.width = "30px"

      document.getElementById("foo3").style.height = "30px"
    } else {
      document.getElementById("foo2").style.height = "50px"
      document.getElementById("foo2").style.clipPath =
        " polygon(0% 0, 100% 0, 100% 100%, 0% 100%)"
      document.getElementById("AltairLogo").style.opacity = "1"

      document.getElementById("foo3").style.width = "20px"

      document.getElementById("foo3").style.height = "15px"
      this.setState({ img: Burger })
      this.setState({ pressed: false })
      this.setState({ MenuVisible: false })
      setTimeout(() => this.setState({ MenuVisible: false }), 200)
    }
  }

  Closemenu = () => {
    if (this.state.pressed) {
      document.getElementById("foo2").style.height = "50px"
      document.getElementById("foo2").style.clipPath =
        " polygon(0% 0, 100% 0, 100% 100%, 0% 100%)"
      document.getElementById("AltairLogo").style.opacity = "1"

      document.getElementById("foo3").style.width = "20px"

      document.getElementById("foo3").style.height = "15px"
      this.setState({ img: Burger })
      this.setState({ pressed: false })
      this.setState({ MenuVisible: false })
      setTimeout(() => this.setState({ MenuVisible: false }), 200)
    }
  }
  render() {
    return (
      <div className="sidenavbar-mb">
        <header>
          <a href="/">
            <motion.img
              src={AltairLogoWhiteSVG}
              alt="Altair Logo"
              id="AltairLogo"
              className="logo non-interactive"
            />
          </a>
        </header>
        <div id="foo2" className="Wrapper-mb">
          <img
            src={this.state.img}
            id="foo3"
            className="SidemenuButton-mb"
            onClick={this.Changepath}
          />

          {this.state.MenuVisible && (
            <nav className="Sidenav-mb">
              <div className="nav-mb-item">
                <Link
                  className="nav-link-mb"
                  activeClass="active"
                  to="Mapleton"
                  spy={true}
                  smooth={true}
                  onClick={this.Closemenu}
                >
                  THE MAPLETON ESTATE
                </Link>
              </div>

              <div class="vl2"></div>

              <div className="nav-mb-item">
                <Link
                  className="nav-link-mb"
                  activeClass="active"
                  to="BelAir"
                  spy={true}
                  smooth={true}
                  onClick={this.Closemenu}
                >
                  THE BEL AIR ESTATE
                </Link>
              </div>
              <div class="vl2"></div>

              <div className="nav-mb-item">
                <Link
                  className="nav-link-mb"
                  activeClass="active"
                  to="RTower"
                  spy={true}
                  smooth={true}
                  onClick={this.Closemenu}
                >
                  ROBERTSON TOWER
                </Link>
              </div>
            </nav>
          )}
        </div>
      </div>
    )
  }
}
export default Sidenav
