import React, { useRef, useState, useEffect, useCallback } from "react"
import Burger from "../../images/burger.png"
import * as Scroll from "react-scroll"

const Link2 = Scroll.Link
const activeStyles = {
  background: "red",
}
class Navs extends React.Component {
  showSettings(event) {
    event.preventDefault()
  }

  constructor(props) {
    super(props)
    this.state = {
      positionIdx: 0,
      dimModeIdx: 0,
      isVisible: false,
      fluid: true,
      customAnimation: false,
      slow: false,
      size: 1,
      mode: "Wrapper",
      img: Burger,
      pressed: false,
    }
  }

  render() {
    return (
      <nav>
        <div className="NavsContainer">
          <div>
            <Link2
              className="Navs-pre3"
              activeClass="active"
              to="Mapleton"
              spy={true}
              smooth={true}
            >
              <pre>
                {`THE
MAPLETON
ESTATE
`}
              </pre>
            </Link2>
          </div>
          <div class="vl3"></div>
          <div>
            <Link2
              className="Navs-pre3"
              activeClass="active"
              to="BelAir"
              id="foo7"
              spy={true}
            >
              <pre>
                {`THE
BEL AIR
ESTATE
`}
              </pre>
            </Link2>
          </div>
          <div class="vl3"></div>
          <div>
            <Link2
              className="Navs-pre3"
              activeClass="active"
              to="RTower"
              id="foo6"
              spy={true}
            >
              <pre>
                {`ROBERTSON
TOWER
`}
              </pre>
            </Link2>
          </div>
        </div>
      </nav>
    )
  }
}
export default Navs
